<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Contact" icon="fa-solid fa-circle-user" />

    <div class="generalBox border shadow-sm mb-0">
        <div class="row">
            <div class="col-12 mb-3">Manage you contacts</div>
            <div class="col-12">Coming soon..</div>
        </div>
    </div>

    <div v-if="isSignOn === true" class="generalBorderlessBox bg-warning">
        <div class="row mb-4">
            <div class="col-12 col-lg-8 pb-1">
                <div class="input-group">
                    <span class="input-group-text"><fa icon="search" /></span>
                    <input type="text" v-model="filterName" class="form-control" placeholder="Name / Email / Tag">
                    <span v-if="filterName && filterName.length > 0" class="input-group-text clearSearch" @click="delfilterName">
                        <fa icon="xmark" />
                    </span>
                </div>
            </div>

            <div class="col-12 col-lg-4 text-end text-primary isLink pt-2">
                <span data-bs-toggle="modal" data-bs-target="#mdlAddUser" class="isLink">
                    <fa icon="user-circle" size="lg" />
                    <span class="me-2 fw-bold" style="font-size: 14px">+</span>Add contact
                </span>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="generalContentBox border shadow-sm">
                    <div class="row">
                        <div class="col-12" ref="scrollComponent">
                            <div v-if="isLoading" class="mb-3 text-center">
                                <fa icon="spinner" size="lg" class="me-2" />Loading...
                            </div>

                            <table width="100%" border="0">
                                <tr>
                                    <td width="55px">
                                        <div v-if="checkedUser && checkedUser.length > 0">
                                            <span class="mx-2 text-grey isLink">
                                                <fa icon="trash" size="lg" style="padding-left: 9px" />
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div v-if="checkedUser && checkedUser.length > 0">
                                            <span class="text-grey small">{{checkedUser.length}} selected</span>
                                        </div>
                                    </td>
                                    <td class="text-end py-2">
                                        <div v-if="!isLoading" class="small fst-italic text-grey">100 of 10,000</div>
                                    </td>
                                </tr>
                            </table>

                            <!-- <div v-if="!isLoading" class="mb-3 text-end small fst-italic">100 of 10,000</div>
            
                            <div class="mb-3" v-if="checkedUser && checkedUser.length > 0">
                                <span class="mx-2 text-grey isLink"><fa icon="trash" size="lg" style="padding-left: 9px" /></span>
                                <span class="float-end text-grey fw-bold">{{checkedUser.length}} selected</span>
                            </div> -->

                            <table class="table table-hover" border="0">
                                <tbody>
                                    <tr v-for="(item, itemIdx) in arrItem" :key="item" >
                                        
                                        <td width="50px" class="text-center">
                                            <!-- Large screen -->
                                            <div class="d-none d-md-block">
                                                <!-- <div v-if="checkedUser.includes(itemIdx)" style="font-size: 16px; margin-left: -5px; padding-top: 6px; padding-bottom: 6px"> -->
                                                <div v-if="checkedUser.includes(itemIdx)" style="font-size: 16px; margin-left: -5px; padding-top: 4px; padding-bottom: 5px">
                                                    <input v-model="checkedUser" type="checkbox" class="form-check-input" :value="itemIdx" />
                                                </div>
                                                <div v-else>
                                                    <span class="hoverHide" :id="'avatar' + itemIdx">
                                                        <ImgAvatar :key="itemIdx" isUser="true" id="" displayName="" :isVerified="item.isVerified" :isActive="item.status" privilege="" align="center" width="30" height="30" />
                                                        <!-- <Avatar :key="itemIdx" align="center" isUser="true" avatar="" displayName="" :isVerified="item.isVerified" :isActive="item.status" width="30" height="30" /> -->
                                                    </span>
                                                    <span class="hoverShow" style="z-index:1000; font-size: 15px; margin-top: -30px; margin-left: -10px; position:absolute;">
                                                        <input v-model="checkedUser" type="checkbox" class="form-check-input" :value="itemIdx" />
                                                    </span>
                                                </div>
                                            </div>
                                            <!-- End Large screen -->

                                            <!-- Small screen -->
                                            <div class="d-block d-md-none">
                                                <span class="hoverHide" :id="'avatar' + itemIdx">
                                                    <ImgAvatar :key="itemIdx" isUser="true" id="" displayName="" :isVerified="item.isVerified" :isActive="item.status" privilege="" width="30" height="30" />
                                                    <!-- <Avatar :key="itemIdx" isUser="true" avatar="" displayName="" :isVerified="item.isVerified" :isActive="item.status" width="30" height="30" /> -->
                                                </span>
                                            </div>
                                            <!-- End Small screen -->
                                        </td>
                                    
                                        <td class="align-middle">
                                            <div class="row">
                                                <div class="col-lg-5">
                                                    <div class="isLink">
                                                        <router-link :to="'/contact/' + item.userId">
                                                            <span class="fw-bold">{{item.fullLegalName}}</span>
                                                            <span class="text-grey"><fa icon="at" class="mx-1"/>{{ item.nickname }}</span>
                                                            
                                                        </router-link>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 small text-grey">{{ item.userId }}</div>
                                                <div class="col-lg-3">
                                                    <div>
                                                        <div class="badge tag" v-for="t in item.tags" :key="t">
                                                            <fa icon="hashtag" class="pe-1"/>{{ t }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-1 text-end d-none d-md-block hoverShow">
                                                    <span class="text-grey isLink me-2 align-middle">
                                                        <Popper class="popperDark" arrow hover content="Delete">
                                                            <fa icon="trash" size="lg" />
                                                        </Popper>
                                                    </span>
                                                </div>
                                                <!-- Small screen -->
                                                <div class="col-12 text-end d-block d-md-none">
                                                    <span class="text-grey isLink me-4">
                                                        <fa icon="trash" size="lg" />
                                                    </span>
                                                    <span>
                                                        <input v-model="checkedUser" type="checkbox" class="form-check-input" :value="itemIdx" />
                                                    </span>
                                                </div>
                                                <!-- End Small screen -->
                                            </div>
                                        </td>
                                    </tr>

                                    <tr v-if="isLoadingMore" class="mt-3 text-center">
                                        <td colSpan="2">
                                            <fa icon="spinner" size="lg" class="me-2" />Loading...
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
    
    <!-- Modal --> 
    <div
        class="modal fade"
        id="mdlAddUser"
        tabindex="-1"
        aria-labelledby="AddUser"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark modal-sm">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title"> Add contact</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 pb-3">
                            <input v-model="email" class="form-control" placeholder="Email">
                        </div>
                        <div class="col-12 pb-3">
                            <input v-model="fullLegalName" class="form-control" placeholder="Full legal name">
                        </div>
                        <div class="col-12 pb-3">
                            <input v-model="nickname" class="form-control" placeholder="Nickname">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" class="me-2" />Save
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 

</template>

<script>
import { ref, inject, onMounted, onUnmounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
// import Avatar from '@/components/Avatar.vue'
import ImgAvatar from '@/components/ImgAvatar.vue'

export default {
    name: 'ContactList',
    components: { Alert, TopNavigation, Popper, /* Avatar, */ ImgAvatar },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const isSignOn = ref(false) // detect if from signon Entity

        const scrollComponent = ref(null)
        const arrItem = ref([])
        const total = ref(0)
        const rcdFr = ref(0) // record index from
        const rcdDisplay = ref(15) // total record to display, ORIG: 15
        const isLoading = ref(true)
        const isLoadingMore = ref(false)

        const clsAvatar = ref('d-block')
        const clsCheckbox = ref('d-none')
        const checkedUser = ref([])

        const filterName = ref('')
        const email = ref(null)
        const fullLegalName = ref(null)
        const nickname = ref(null)
        const title = ref('Mr')
        const gender = ref('M')
        const userPackage = ref('FREE')
        const gmt = ref('+8:00')
        const language = ref('EN')
        const currency = ref('MYR')
        const gmtOpt = ref(null)
        const titleOpt = ref(null)
        const genderOpt = [
            { label: 'Female', value: 'F'},
            { label: 'Male', value: 'M'}
        ]

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const handleScroll = (e) => {
            
            let element = scrollComponent.value
            if (element !== null) {
                console.info('---- handleScroll', element.getBoundingClientRect().bottom.toFixed(0), window.innerHeight)
                if (element.getBoundingClientRect().bottom.toFixed(0)*1 < window.innerHeight) {
                    isLoadingMore.value = true
                    loadMore()
                }
            }
        }

        watch([checkedUser], () => {
            console.info('watch', JSON.stringify(checkedUser.value))
        })

        const getGmt = () => {
            // console.info('gmt', new Date())
            let curDate = new Date() + ''
            let tmp = curDate.split('GMT')
            let tmp1 = tmp[1].split(' ')
            let tmpGmt = tmp1[0].replace(' ', '')
            gmt.value = tmpGmt.substr(0, 3) + ':' + tmpGmt.substr(3, 2)

            axios.get('/reference/gmt')
                .then((res) => {
                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        gmtOpt.value = res.data.data
                    }
                })
                .catch((error) => {
                    console.log('getGmt | ERR', JSON.stringify(error))
                })
        }

        const getTotal = () => {

            total.value = 8
            /* axios.post('/report/dash/signon/signed', {})
            .then((res) => {

                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else if (res.data.status === 1) {
                    total.value = res.data.data.length
                } 
            })
            .catch((error) => {
                console.log('getTotal | ERR', JSON.stringify(error))
            }) */


        }

        const getList = (loadMore) => {

            isLoading.value = false
            isLoadingMore.value = false

            arrItem.value = [
                {
                    userId: 'joey1980@gmail.com',
                    fullLegalName: 'Tan Joe Er',
                    nickname: 'Joey80',
                    avatar: '',
                    isVerified: false,
                    tags: ['mcis', 'management']
                },
                {
                    userId: 'alex.hang@yahoo.com',
                    fullLegalName: 'Hang Kar Meng',
                    nickname: 'Alex.Hang',
                    avatar: '',
                    isVerified: false,
                    tags: ['colleague', 'development']
                },
                {
                    userId: 'mohamad1234@yahoo.com',
                    fullLegalName: 'Mohamad bin Abdullah',
                    nickname: 'Mohamad',
                    avatar: '',
                    isVerified: true,
                    tags: ['colleague', 'cs']
                },
                {
                    userId: 'grace@gmail.com',
                    fullLegalName: '',
                    nickname: 'Grace',
                    avatar: '',
                    isVerified: false,
                    tags: []
                },
                {
                    userId: 'kelly@gmail.com',
                    fullLegalName: 'Kek Hai Yan',
                    nickname: 'Kelly Kek',
                    avatar: '',
                    isVerified: false,
                    tags: ['mcis']
                },
                {
                    userId: 'jason.chan@yahoo.com',
                    fullLegalName: 'Lim Chun Kiat',
                    nickname: 'Jason',
                    avatar: '',
                    isVerified: false,
                    tags: ['unifi']
                },
                {
                    userId: 'patrick@gmail.com',
                    fullLegalName: 'Teoh Xin Zhan',
                    nickname: 'Patrick',
                    avatar: '',
                    isVerified: false,
                    tags: ['colleague', 'support']
                },
                {
                    userId: 'yennee.lim@genusis.com',
                    fullLegalName: 'Lim Yen Nee',
                    nickname: 'YNLim',
                    avatar: '',
                    isVerified: true,
                    tags: ['colleague', 'development']
                },

            ]
            /* const p = {
                orderBy: [
                    {
                        field: 'dateCreate',
                        order: 'desc'
                    }
                ],
                limit: rcdFr.value + ',' + rcdDisplay.value
            }

            console.info('getSig params', JSON.stringify(p))
            axios.post( '/report/dash/signon/signed', p
            ).then((res) => {

                if (loadMore) {
                    console.info('load more here')
                    isLoadingMore.value = false

                } else {
                    console.info('x load more here')
                    isLoading.value = false
                }

                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else if (res.data.status === 1) {
                   if (res.data.data.length > 0) {
                        arrItem.value.push(...res.data.data)
                    }
                } 
            })
            .catch((error) => {
                console.log('getList ERR', JSON.stringify(error))
            }) */
        }

        const loadMore = () => {
            if (rcdFr.value < total.value) {
                rcdFr.value = rcdFr.value + rcdDisplay.value
                getList(true)

            } else {
                isLoadingMore.value = false
            }
        }

        const delfilterName = () => {
            arrItem.value = []
            filterName.value = ''
            getList(null)
        }

        onMounted(() => {
            window.addEventListener("scroll", handleScroll)
            
            if (func.isEmptyObject(localStorage.getItem('entity')) === false) {
                let objLSEntity = func.decodeStrToJsonSimple(localStorage.getItem('entity'))
                if ( objLSEntity.entityId === '1') {
                    isSignOn.value = true
                }
            }

            rcdFr.value = 0
            
            getGmt()
            getTotal()
            getList(false)

            axios.get('/reference/title')
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        titleOpt.value = res.data.data

                    } else {
                        func.addLog('contact', 'onMounted', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('contact', 'onMounted - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        })

        onUnmounted(() => {
            window.removeEventListener("scroll", handleScroll)
        })

        return {
            alert, closeAlert, router, func, store, scrollComponent, 
            total, clsAvatar, clsCheckbox, checkedUser, 
            arrItem, rcdFr, rcdDisplay, isLoading, isLoadingMore, filterName, 
            email, fullLegalName, nickname, title, gender, userPackage, 
            gmt, language, currency, gmtOpt, titleOpt, genderOpt, delfilterName, isSignOn
        }
    }
}
</script>

<style>
.table-hover > tbody > tr .hoverShow {
    visibility: hidden;
}

.table-hover > tbody > tr:hover .hoverShow {
    visibility: visible;
}

.table-hover > tbody > tr .hoverHide {
    visibility: visible;
}

.table-hover > tbody > tr:hover .hoverHide {
    visibility: hidden;
}

.input-group-text {
    background-color: var(--bs-primary);
    color: var(--bgcolor-body-color);
}

.clearSearch {
  cursor: pointer; 
  color: #53BDEB;
  background-color: var(--bgcolor-body-color); 
  border-color: var(--accent-color)
}
</style>